import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { Link } from "gatsby"

import Layout from "components/Layout/en"

import partnershipImg from "./partnershipImg.jpg"
import droneImg from "./droneImg.jpeg"
import hardwareImg from "./hardwareImg.jpg"
import BasicPartnerLogo from "./Basic.png"
import GoldPartnerLogo from "./Gold.png"
import Image from "components/Image/index"

export default function(props) {
  const header = {
    x: 44,
    y: 33,
    text: "Together we will accomplish more",
    description:
      "Do you have your own ideas or are you an expert from another industry? Benefit from the partnership with MoonVision.",
    image: partnershipImg,
  }
  return (
    <Layout header={header} location={props.location}>
      <Container>
        <Row className="py-5 text-center">
          <Col md={{ span: 8, offset: 2 }}>
            <h2>Together we create new solutions for the digital future</h2>
            <p>
              Would you like to add Computer Vision AI (artificial intelligence)
              to your products and services? Then become our partner and benefit
              from our many years of experience and our innovative spirit!
              Together we can use visual data to conquer new areas, drive
              innovations and develop new business models.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md="7">
            <div className="grey-background p-2">
              <h2>Become a Hardware Partner</h2>
              <p>Together we offer new Areas</p>
              <h3>Advantages of a Hardware Partnership</h3>
              <p>
                As a Hardware-Partner, e.g.: facility manufacturer, microscope
                or camera manufacturer, we offer you the opportunity to present
                yourself as a total solution provider to your customers by
                integrating the MoonVision image processing software using AI.
                Artificial intelligence can perform tasks more efficiently and
                error-free than ever before, enabling automation, manual effort
                reduction and improved reporting.
              </p>
              <ul>
                <li>Access to MoonVision’s network</li>
                <li>
                  Increase your sales opportunities as a partner of MoonVision
                </li>
                <li>
                  Offer your customers a complete solution comprising hardware
                  and software
                </li>
              </ul>
              <Button
                onClick={() => {
                  window.$crisp && window.$crisp.push(["do", "chat:open"])
                }}
              >
                Become a Partner now !
              </Button>
            </div>
          </Col>
          <Col md="5">
            <Image filename={hardwareImg} alt="Chipset Board" />
          </Col>
        </Row>
        <Row className="pt-5">
          <Col>
            <Image
              filename={BasicPartnerLogo}
              alt="basic partner logo"
              className="my-3"
              width="400"
            />
          </Col>
          <Col>
            <Image
              filename={GoldPartnerLogo}
              alt="gold partner logo"
              className="my-3"
              width="400"
            />
          </Col>
        </Row>
        <Row className="pb-3">
          <Col className="bluebox">
            <p>
              As a Certified.Partner, we guarantee our customers the fastest
              possible feedback, close exchange and optimum harmony between
              hardware and software. Together we offer highest operational
              stability and define a new quality level for our customers.
            </p>
            <Link
              to="partnership/certified-partner"
              style={{ color: "white", textDecoration: "underline" }}
            >
              More About Certified.Partner
            </Link>
          </Col>
        </Row>
        <Row className="py-5">
          <Col md="5">
            <img src={droneImg} alt="hardware partner" />
          </Col>
          <Col md="7">
            <div className="grey-background p-2">
              <h2>Become a Solution Partner</h2>
              <p>Gemeinsam bieten wir neue Bereiche</p>

              <h3>Advantages of a Solution-Partnership</h3>
              <p>
                As an expert, in such as household appliance manufacturer,
                landscape planner, drone operator or mobile solution specialist
                in the industry, you already provide your customers with
                specific solutions. Now it is time to build new digital business
                models or to extend your software with automated procedures in
                image recognition and processing using AI.
              </p>
              <ul>
                <li>
                  As a Solution Partner, you continue to be the first point of
                  contact for your customers
                </li>
                <li>
                  Automated & error-free operation in image processing by
                  MoonVision
                </li>
                <li>
                  Support for seamless integration of interfaces to create
                  common solutions
                </li>
              </ul>
              <Button
                onClick={() => {
                  window.$crisp && window.$crisp.push(["do", "chat:open"])
                }}
              >
                Become a Partner now
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
